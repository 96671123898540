export enum ProductStage {
  ComingSoon = 'comingsoon',
  Live = 'live',
  Failed = 'failed',
  Past = 'past',
  Demo = 'demo',
  Draft = 'draft',
  Petition = 'petition',
  PetitionFailed = 'failed-petition',
  PetitionSuccess = 'successful-petition',
}

export enum ProductType {
  Plushie = 'Plushie',
  Longboi = 'Longboi',
  Charm = 'Charm',
  Sketchbook = 'Sketchbook',
  Keycap = 'Keycap',
  Backpack = 'Backpack',
  PlushKeychain = 'Plush Keychain',
  KeychainPlushie = 'Keychain Plushie',
  JumboPlush = 'Jumbo Plush',
  SquishyPlush = 'Squishie',
  VinylFigure = 'Vinyl Figure',
  FannyPack = 'Fanny Pack',
  EnamelPin1Pack = 'Enamel Pin 1 Pack',
  EnamelPin2Pack = 'Enamel Pin 2 Pack',
  EnamelPin3Pack = 'Enamel Pin 3 Pack',
  Hoodie = 'Hoodie',
  Doughboi = 'Doughboi',
}

type ProductMOQ = {
  [key: string]: number;
};

export const productMOQ: ProductMOQ = {
  Plushie: 200,
  Longboi: 200,
  Charm: 100,
  Sketchbook: 500,
  Keycap: 100,
  Backpack: 200,
  'Plush Keychain': 500,
  'Keychain Plushie': 500,
  'Jumbo Plush': 200,
  'Jumbo Plushie': 200,
  Squishie: 500,
  'Vinyl Figure': 500,
  'Fanny Pack': 200,
  'Enamel Pin 1-Pack': 200,
  'Enamel Pin 2-Pack': 200,
  'Enamel Pin 3-Pack': 200,
  Hoodie: 300,
  Doughboi: 200,
};

export enum ShopPolicy {
  PrivacyPolicy = 'privacy-policy',
  RefundPolicy = 'refund-policy',
  TermsOfService = 'terms-of-service',
  ShippingPolicy = 'shipping-policy',
}

export enum AddressType {
  Primary = 'Primary Address',
  Secondary = 'Secondary Address',
}

export enum SortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}

export enum ProductTag {
  Hidden = 'hidden',
  Limited = 'limited',
  HideUnits = 'hide-units',
  Evergreen = 'evergreen',
  Live = 'live',
  Petition = 'petition',
  SuccessfulPetition = 'successful-petition',
  FailedPetition = 'failed-petition',
  HideFromMasthead = 'hide-from-masthead',
  FundedBarOverride = 'funded-bar-override',
  Hololive = 'hololive',
  Draft = 'draft',
}

export enum CartEventLocations {
  CartPopout = 'cart_popout',
  CartPage = 'cart_page',
  ProductThumbnail = 'product_thumbnail',
  ProductPage = 'product_page',
  StickyCartButton = 'product_page_sticky',
  CompleteTheCollection = 'complete_the_collection',
  CartAddOn = 'cart_add_on',
  GiftButton = 'gift_button',
  ZoomModal = 'zoom_modal',
}

export enum BannerType {
  Standard = 'standard',
  BlueOnGreen = 'blueOnGreen',
  LightBlue = 'lightBlue',
  DarkTextOnPink = 'darkTextOnPink',
}

export type PromotionContent = {
  bannerType: BannerType;
  text: string;
  link?: string;
  linkText?: string;
  subtitle?: string;
  icon?: string;
  posthogTracking?: string;
};

export enum EvergreenInventoryState {
  InStock = 'in_stock',
  LowStock = 'low_stock',
  SoldOut = 'sold_out',
}

export enum BadgeType {
  Support = 'support',
  Gift = 'gift',
}

export enum BadgeText {
  Sale = 'Sale',
  SoldOut = 'Sold Out',
  LowStock = 'Low Stock',
  ComingSoon = 'Coming Soon',
  PastCampaign = 'Past Campaign',
  Concept = 'Petition: Concept',
  GoalReached = 'Petition: Prototype Pending!',
  GoalNotReached = 'Petition: Goal Not Reached',
  NotFunded = 'Not Funded',
  PetitionSuccess = 'Past Petition',
}
