import { ApolloClient } from '../clients';
import {
  GET_CHECKOUT_COLLECTION_BY_HANDLE,
  GET_COLLECTION_BY_HANDLE,
  GET_COLLECTION_BY_HANDLE_DETAILED,
  GET_COLLECTION_BY_QUERY,
} from './queries';
import config from '../../../config.json';
import { filterTrueMetafieldsCollections } from '../../utils/product';
import { logAndThrowSentryError } from '../../utils/errors';

const { pagination } = config;

export const getCollectionByHandle = (
  handle: string,
  cursor?: string,
  images = 1,
  first = pagination.products,
): Promise<Shopify.Collection | null> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_COLLECTION_BY_HANDLE,
    variables: {
      handle,
      first,
      cursor,
      images,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getCollectionByHandle', error);
    return data?.collectionByHandle || null;
  });

export const getCheckoutCollectionsByHandles = (
  handles: string[],
  cursor?: string,
  images = 1,
  first = pagination.products,
): Promise<Shopify.Collection[]> => {
  const promises = handles.map((handle) =>
    ApolloClient.query<Shopify.QueryRoot>({
      query: GET_CHECKOUT_COLLECTION_BY_HANDLE,
      variables: {
        handle,
        first,
        cursor,
        images,
      },
    }),
  );
  return Promise.all(promises).then(
    (items) =>
      items
        .map(({ data }) => data?.collectionByHandle || null)
        .filter((maybeCollection) => maybeCollection !== null) as Shopify.Collection[],
  );
};

export const getCollectionByHandleDetailed = (
  handle: string,
  cursor?: string,
  first = pagination.products,
  maxWidth = 1000,
): Promise<Shopify.Collection | null> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_COLLECTION_BY_HANDLE_DETAILED,
    variables: {
      handle,
      first,
      cursor,
      maxWidth,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getCollectionByHandleDetailed', error);
    return data?.collectionByHandle || null;
  });

export const getCreatorCollectionsByQuery = (
  query: string,
  first = 25,
  after = '',
  sortKey = 'ID',
  reverse = false,
  images = 1,
  namespace = 'global',
  key = 'isCreatorCollection',
): Promise<Shopify.ProductEdge[]> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_COLLECTION_BY_QUERY,
    variables: {
      query,
      first,
      after,
      sortKey,
      reverse,
      images,
      namespace,
      key,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getCreatorCollectionsByQuery', error);
    return filterTrueMetafieldsCollections(data?.collections.edges);
  });

export const getSearchableCollectionsByQuery = (
  query: string,
  first = 25,
  after = '',
  sortKey = 'ID',
  reverse = false,
  images = 1,
  namespace = 'global',
  key = 'isSearchableCollection',
): Promise<Shopify.ProductEdge[]> =>
  ApolloClient.query<Shopify.QueryRoot>({
    query: GET_COLLECTION_BY_QUERY,
    variables: {
      query,
      first,
      after,
      sortKey,
      reverse,
      images,
      namespace,
      key,
    },
  }).then(({ data, error }) => {
    if (error) logAndThrowSentryError('getSearchableCollectionsByQuery', error);
    return filterTrueMetafieldsCollections(data?.collections.edges);
  });
