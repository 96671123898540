/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-globals */
import ReactGA4 from 'react-ga4';
import posthog from 'posthog-js';

import { CartEventLocations, ProductStage, ProductTag, productMOQ, ProductType } from '../types/common';
import { decryptShopifyId } from './id';
import {
  getCheckoutTokenFromShopifyGID,
  getEvergreenInventoryState,
  getProductIDFromShopifyGID,
  getProductType,
  getStage,
  hasProductTag,
  parseMetafields,
} from './product';

import config from '../../config.json';
import { FlagBagType, experimentVariantMap } from '../context/flagging';
import { hasPetitionLaunched } from '../components/pages/petitions/commonFunctions';

declare const ga: any;
declare const window: any;

const DEFAULT_TITLE = 'Default Title';

const sendPixelEvent = (event: string, data: any) => {
  import('react-facebook-pixel')
    .then((x) => x.default)
    .then((ReactPixel) => {
      if (!process.env.NEXT_PUBLIC_FACEBOOK_PIXEL) {
        return;
      }

      ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL);
      ReactPixel.track(event, data);
    });
};

const clearPreviousGTMEvent = (dataLayer: any[]) => {
  dataLayer.push({ event: null, category: null, action: null, label: null, method: null, ecommerce: null });
};

export const sendGTMEvent = (category: string, action?: string, label?: string) => {
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  dataLayer.push({
    event: 'eventTracking',
    category,
    action,
    label,
  });
};

export const sendAddUnselectedVariantToCartEvent = (productName: string) =>
  sendGTMEvent('Button', 'Clicked - Disabled', productName);

export const sendShareBadgeEvent = (networkName: string) =>
  sendGTMEvent('Button', 'Clicked - Share Badge', networkName);

export const getGALinkerParam = () => {
  if (typeof ga == 'undefined' || typeof ga.getAll == 'undefined') {
    return '';
  }

  const trackers = ga.getAll();
  return trackers[0]?.get('linkerParam');
};

export const sendViewItemEvent = (product: Shopify.Product, variant?: Shopify.ProductVariant) => {
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  dataLayer.push({
    event: 'productDetailView',
    ecommerce: {
      detail: {
        products: [
          {
            name:
              variant?.title && variant?.title !== DEFAULT_TITLE
                ? `${product.title} - ${variant?.title}`
                : product.title,
            id: variant?.sku || variant?.id || product.id,
            price: variant?.priceV2.amount,
            brand: product.vendor,
            category: product.productType,
            variant: variant?.title && variant?.title !== DEFAULT_TITLE ? variant?.title : null,
          },
        ],
      },
    },
  });
};

export const sendGAEvent = (eventTitle: string) => {
  ReactGA4.gtag('event', eventTitle);
};

export const trackClickAnalytics = () => {
  const wrapper = document.getElementById('__next');

  if (wrapper === null) {
    return;
  }

  wrapper.addEventListener('click', (event) => {
    if (
      event &&
      event.target &&
      event.target instanceof Element &&
      event.target.tagName !== 'DIV' &&
      event.target.textContent &&
      event.target.textContent.length > 2
    ) {
      const sendTag = `click_${event.target.tagName
        .toLowerCase()
        .replace(' ', '_')}_${event.target.textContent.toLowerCase().replace(' ', '_')}${
        event.target.id ? `_${event.target.id.toLowerCase().replace(' ', '_')}` : ''
      }`;

      sendGAEvent(sendTag);
    }
  });
};

// Below is Analytics Tracking Helper functions
// ===========================================

const getEventIDFromName = (name: string): string => name.toLowerCase().trim().split(' ').join('_');

const isProductFunded = (product: Shopify.Product): boolean => {
  const metafields = parseMetafields(product.metafields);

  const totalInventory = Math.abs(product.totalInventory || 0);
  const moq = Number(metafields.moq) || productMOQ[product.productType] || config.defaultMOQ;

  return totalInventory >= moq;
};

export const getCreator = (product: Shopify.Product): string => {
  const metafields = parseMetafields(product.metafields);
  return metafields.creator ? metafields.creator : '';
};

const getLaunchString = (product: Shopify.Product): string => {
  const metafields = parseMetafields(product.metafields);
  return metafields.launch ? metafields.launch : '';
};

export const isUserLoggedIn = (user: User | undefined): boolean => user !== undefined && user.customer !== undefined;

export const calculateMinutesLeft = (metafields: Metafields, stage: ProductStage): number => {
  if (stage === ProductStage.PetitionFailed || stage === ProductStage.Failed || stage === ProductStage.Past) {
    return 0;
  }

  let date: string | undefined;
  if ([ProductStage.Live, ProductStage.ComingSoon, ProductStage.Draft].includes(stage)) {
    date = stage === ProductStage.ComingSoon || stage === ProductStage.Draft ? metafields.launch : metafields.enddate;
  } else {
    // For Petitions
    date = hasPetitionLaunched(metafields.petitionLaunchDate)
      ? metafields.petitionEndDate
      : metafields.petitionLaunchDate;
  }

  if (!date) {
    return -1;
  }

  const timestampDate = new Date(date);
  const now = new Date();
  const timeDifferenceMs = now.getTime() - timestampDate.getTime();
  const minutes = Math.floor(timeDifferenceMs / (1000 * 60));

  return minutes;
};

// Below is Analytics Events
// ================================
export const analyticsTrackSignUp = () => {
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  dataLayer.push({
    event: 'sign_up',
    method: 'storefront',
  });
};

export const analyticsTrackListInView = (
  rawProducts: Shopify.ProductEdge[] | undefined,
  eventName: string | undefined,
  user: User | undefined,
) => {
  if (!rawProducts || !eventName) {
    return;
  }

  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  const trackingItems: AnalyticsItem[] = [];
  const products: Shopify.Product[] = [];

  for (const product of rawProducts) {
    products.push(product.node);
  }

  for (const product of products) {
    const item: AnalyticsItem = {
      item_id: product.id,
      item_name: product.title,
      affiliation: 'Makeship Store',
      item_brand: getCreator(product),
      item_category: isProductFunded(product) ? 'Funded' : 'Not Funded',
      item_category2: product.productType,
      item_list_id: getEventIDFromName(eventName),
      item_list_name: eventName,
      item_category3: getLaunchString(product),
    };
    trackingItems.push(item);
  }

  dataLayer.push({
    event: 'view_item_list',
    is_logged_in: isUserLoggedIn(user),
    ecommerce: {
      items: trackingItems,
    },
  });
};

export const analyticsTrackItemSelectedFromList = (
  product: Shopify.Product,
  eventName: string | undefined,
  user: User | undefined,
) => {
  if (!eventName) {
    return;
  }

  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  const trackingItems: AnalyticsItem[] = [];

  const item: AnalyticsItem = {
    item_id: product.id,
    item_name: product.title,
    affiliation: 'Makeship Store',
    item_brand: getCreator(product),
    item_category: isProductFunded(product) ? 'Funded' : 'Not Funded',
    item_category2: product.productType,
    item_list_id: getEventIDFromName(eventName),
    item_list_name: eventName,
    item_category3: getLaunchString(product),
  };
  trackingItems.push(item);

  dataLayer.push({
    event: 'select_item',
    is_logged_in: isUserLoggedIn(user),
    ecommerce: {
      items: trackingItems,
    },
  });
};

export const analyticsTrackProductPageLoad = (
  product: Shopify.Product,
  variant: Shopify.ProductVariant | undefined,
  user: User | undefined,
) => {
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  const trackingItems: AnalyticsItem[] = [];

  const item: AnalyticsItem = {
    item_id: product.id,
    item_name: product.title,
    affiliation: 'Makeship Store',
    item_brand: getCreator(product),
    item_category: isProductFunded(product) ? 'Funded' : 'Not Funded',
    item_category2: product.productType,
    item_category3: getLaunchString(product),
  };
  trackingItems.push(item);

  dataLayer.push({
    event: 'view_item',
    is_logged_in: isUserLoggedIn(user),
    ecommerce: {
      items: trackingItems,
    },
  });

  sendPixelEvent('ViewContent', {
    content_ids: [decryptShopifyId(product.id)],
    content_type: 'product_group',
    content_name: product.title,
    content_category: product.productType,
    currency: 'USD',
    value: variant?.priceV2.amount,
  });
};

export const analyticsTrackAddToCart = (
  product: Shopify.Product,
  quantity: number,
  user: User | undefined,
  eventLocation: CartEventLocations,
  variant?: Shopify.ProductVariant,
  campaignID?: number,
  isBuyNow?: boolean,
  isPlushPinBundle?: boolean,
) => {
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  const trackingItems: AnalyticsItem[] = [];

  const price = parseFloat(variant?.priceV2.amount) || 0.0;

  const item: AnalyticsItem = {
    item_id: product.id,
    item_name: product.title,
    affiliation: 'Makeship Store',
    currency: variant?.priceV2.currencyCode.toString(),
    item_brand: getCreator(product),
    item_category: isProductFunded(product) ? 'Funded' : 'Not Funded',
    item_category2: product.productType,
    item_variant: variant ? variant.title : '',
    price,
    quantity,
    item_category3: getLaunchString(product),
    item_category4: variant ? variant.title : '',
  };
  trackingItems.push(item);

  dataLayer.push({
    event: 'add_to_cart',
    event_location: eventLocation,
    is_logged_in: isUserLoggedIn(user),
    is_buy_now: isBuyNow || false,
    ecommerce: {
      items: trackingItems,
    },
    is_plush_pin_bundle: isPlushPinBundle || false,
  });

  sendPixelEvent('AddToCart', {
    content_ids: [decryptShopifyId(product.id)],
    content_type: 'product_group',
    content_name: product.title,
    content_category: product.productType,
    currency: 'USD',
    value: variant?.priceV2.amount,
    num_items: 1,
  });

  posthog.capture('add_to_cart', {
    shopify_product_ID: getProductIDFromShopifyGID(product.id),
    event_location: eventLocation,
    quantity,
    product_name: product.title,
    price,
    creator: item.item_brand,
    campaignID,
    is_buy_now: isBuyNow || false,
    evergreen_inventory_state: getEvergreenInventoryState(product),
    is_plush_pin_bundle: isPlushPinBundle || false,
  });

  if (typeof window !== 'undefined' && window.TriplePixel) {
    window.TriplePixel('AddToCart', { item: getProductIDFromShopifyGID(product.id), q: quantity });
  }

  if (typeof window !== 'undefined' && window.rdt) {
    window.rdt('track', 'AddToCart', { item: getProductIDFromShopifyGID(product.id), q: quantity });
  }
};

export const analyticsTrackViewCart = (
  rawProducts: Shopify.CheckoutLineItemEdge[] | undefined,
  rawTotal: string | undefined,
  user: User | undefined,
  eventLocation: string,
) => {
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  let products = rawProducts;
  if (!products) {
    products = [];
  }

  let total = rawTotal;
  if (!total) {
    total = '0.0';
  }

  const trackingItems: AnalyticsItem[] = [];

  for (const product of products) {
    const price = parseFloat(product.node.variant?.priceV2.amount) || 0.0;

    const item: AnalyticsItem = {
      item_id: getProductIDFromShopifyGID(product.node.variant?.product?.id || ''),
      item_name: product.node.title,
      affiliation: 'Makeship Store',
      currency: product.node.variant?.priceV2.currencyCode.toString(),
      item_brand: product.node.variant ? getCreator(product.node.variant.product) : '',
      item_category: product.node.variant && isProductFunded(product.node.variant.product) ? 'Funded' : 'Not Funded',
      item_category2: product.node.variant ? product.node.variant.product.productType : 'Plushie',
      item_variant: product.node.variant ? product.node.variant.title : '',
      price,
      quantity: product.node.quantity,
      item_category4: product.node.variant ? product.node.variant.title : '',
    };
    trackingItems.push(item);
  }

  const currency =
    products && products.length && products[0].node.variant
      ? products[0].node.variant?.priceV2.currencyCode.toString()
      : 'USD';

  dataLayer.push({
    event: 'view_cart',
    event_location: eventLocation,
    is_logged_in: isUserLoggedIn(user),
    value: parseFloat(total),
    currency,
    ecommerce: {
      items: trackingItems,
    },
  });
};

export const analyticsTrackRemoveFromCart = (
  rawProduct: Shopify.CheckoutLineItem,
  user: User | undefined,
  eventLocation: string,
) => {
  if (!rawProduct.variant) {
    return;
  }

  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  const { product } = rawProduct.variant;

  const trackingItems: AnalyticsItem[] = [];

  const price = parseFloat(rawProduct.variant.priceV2.amount) || 0.0;

  const item: AnalyticsItem = {
    item_id: product.id,
    item_name: product.title,
    affiliation: 'Makeship Store',
    currency: rawProduct.variant.priceV2.currencyCode.toString(),
    item_brand: getCreator(product),
    item_category2: product.productType,
    item_variant: rawProduct.variant.title,
    price,
    quantity: rawProduct.quantity,
    item_category4: rawProduct.variant.title,
  };
  trackingItems.push(item);

  dataLayer.push({
    event: 'remove_from_cart',
    event_location: eventLocation,
    is_logged_in: isUserLoggedIn(user),
    ecommerce: {
      items: trackingItems,
    },
  });
};

export const analyticsTrackStartCheckout = (
  rawProducts: Shopify.CheckoutLineItemEdge[] | undefined,
  user: User | undefined,
  eventLocation: string,
  checkoutID: string | undefined,
) => {
  const dataLayer = (typeof window !== 'undefined' && window.dataLayer) || [];
  clearPreviousGTMEvent(dataLayer);

  let products = rawProducts;
  if (!products) {
    products = [];
  }

  const trackingItems: AnalyticsItem[] = [];

  for (const product of products) {
    const price = parseFloat(product.node.variant?.priceV2.amount) || 0.0;

    const item: AnalyticsItem = {
      item_id: getProductIDFromShopifyGID(product.node.variant?.product?.id || ''),
      item_name: product.node.title,
      affiliation: 'Makeship Store',
      currency: product.node.variant?.priceV2.currencyCode.toString(),
      item_brand: product.node.variant ? getCreator(product.node.variant.product) : '',
      item_category2: product.node.variant ? product.node.variant.product.productType : 'Plushie',
      item_variant: product.node.variant ? product.node.variant.title : '',
      price,
      quantity: product.node.quantity,
      item_category4: product.node.variant ? product.node.variant.title : '',
    };
    trackingItems.push(item);
  }

  posthog.capture('checkout_started', {
    checkout_token: getCheckoutTokenFromShopifyGID(checkoutID || ''),
    event_location: eventLocation,
    is_logged_in: isUserLoggedIn(user),
    items: trackingItems,
  });

  dataLayer.push({
    event: 'begin_checkout',
    event_location: eventLocation,
    is_logged_in: isUserLoggedIn(user),
    ecommerce: {
      items: trackingItems,
    },
  });
};

// Prevent default posthog $pageview autocapture from firing on specific routes
// because we have a different capture event for that route already
export const routeHasNotBeenCaptured = (route: string): boolean => {
  // Add new routes to exclude here
  const excludedRoutes = ['/products/', '/petitions/'];
  let routeHasNotBeenCaptured = true;

  excludedRoutes.forEach((excludedRoute) => {
    if (route.includes(excludedRoute)) routeHasNotBeenCaptured = false;
  });
  return routeHasNotBeenCaptured;
};

export const convertFlagBagIntoPosthogRecords = (flagBag?: FlagBagType) => {
  const posthogRecords: Record<string, string | boolean> = {};

  if (!flagBag) {
    return posthogRecords;
  }

  const { featureFlags, experimentFlags } = flagBag;

  Object.keys(featureFlags).forEach((key) => {
    if (featureFlags[key]) {
      posthogRecords[key] = true;
    }
  });

  Object.keys(experimentFlags).forEach((key) => {
    const experimentVariant = experimentFlags[key];
    posthogRecords[key] = experimentVariantMap[experimentVariant];
  });

  return posthogRecords;
};

export const getCampaignType = (product: Shopify.Product): string => {
  if (hasProductTag(product.tags, ProductTag.Limited)) return 'limited_quantity';
  if (hasProductTag(product.tags, ProductTag.Evergreen)) return 'evergreen';
  if (
    hasProductTag(product.tags, ProductTag.Petition) ||
    hasProductTag(product.tags, ProductTag.SuccessfulPetition) ||
    hasProductTag(product.tags, ProductTag.FailedPetition)
  )
    return 'petition';
  return 'time_limited';
};

export const trackSubscribeButtonClicked = (product: Shopify.Product, user: User | undefined, ctaText: string) => {
  posthog.capture('pdp_subscribeModal_open', {
    product_id: getProductIDFromShopifyGID(product.id),
    product_name: product.title,
    creator: getCreator(product),
    campaign_type: getCampaignType(product),
    campaign_status: getStage(product.tags),
    is_logged_in: isUserLoggedIn(user),
    cta_text: ctaText,
  });
};

export const getPDPTrackingLocation = (product: Shopify.Product) => {
  let eventLocation = getCampaignType(product);
  if (eventLocation === 'time_limited') eventLocation = 'pdp';
  if (getProductType(product.productType) === ProductType.Hoodie) eventLocation = `${ProductType.Hoodie}_pdp`;
  return eventLocation.toLowerCase();
};

export const handleSupportLinkTracking = (supportLinkType: string, eventLocation: string, product: Shopify.Product) => {
  posthog.capture('support_link_click', {
    supportLinkType,
    eventLocation,
    productName: product.handle,
    productID: getProductIDFromShopifyGID(product.id),
  });
};

export const handleHelpGuideModalClick = (
  product: Shopify.Product,
  setIsHelpGuideOpen: (isWhyToggleActive: boolean) => void,
) => {
  posthog.capture('petition_helpGuide_click', {
    productID: product.id,
    productStage: getStage(product.tags),
  });
  setIsHelpGuideOpen(true);
};
