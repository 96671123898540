/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { NextComponentType } from 'next';
import { AppContext, AppProps } from 'next/app';
import { NextRouter, useRouter } from 'next/router';
import styled, { ThemeProvider } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { Analytics } from '@vercel/analytics/react';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/nextjs';

import Cookies from 'js-cookie';
import AlertServiceProvider from '../components/Alert';
import CookieBar from '../components/CookieBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { AppContext as StoreContext } from '../store';
import { light } from '../styles/themes';

import '../../public/styles/zoom.css';
import '../../public/styles/normalize.css';
import '../../public/styles/global.css';
import '../../public/styles/flickity.css';
import { convertFlagBagIntoPosthogRecords, routeHasNotBeenCaptured, trackClickAnalytics } from '../utils/analytics';
import { fetchUserIdentifier } from '../utils/localstorage';
import { FlagBagType, FlaggingContext, emptyFlagBag } from '../context/flagging';
import { SpamFlaggingProvider } from '../context/spamFlagging';
import FlaggingComponent from '../components/FlaggingComponent';

const ContainerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.containerBackground};
`;

const Container = styled.div`
  min-height: 100vh;
  max-width: ${({ theme }) => theme.siteWidth};
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.neutral2};
  word-wrap: break-word;
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral1};
  padding-bottom: 48px;
`;

interface WebsiteAppInitialProps {
  flagBag?: FlagBagType;
}

const WebsiteApp: NextComponentType<AppContext, WebsiteAppInitialProps, AppProps> = ({
  Component,
  pageProps,
}: AppProps<WebsiteAppInitialProps>) => {
  const router = useRouter();

  const flagBag = pageProps?.flagBag || emptyFlagBag;

  const initPostHog = (router: NextRouter) => {
    if (process.env.NEXT_PUBLIC_POSTHOG_ENABLED !== 'true') return;

    const anonID = fetchUserIdentifier();

    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_STOREFRONT_KEY as string, {
      api_host: `${window.location.protocol}//${window.location.host}/ingest`,
      autocapture: false,
      capture_pageview: false,
      loaded: (posthog) => posthog.identify(anonID),
      bootstrap: {
        distinctID: anonID,
        featureFlags: convertFlagBagIntoPosthogRecords(flagBag),
      },
    });

    if (routeHasNotBeenCaptured(router.pathname)) posthog.capture('$pageview');

    router.events.on('routeChangeComplete', (route) => {
      if (routeHasNotBeenCaptured(route)) posthog.capture('$pageview');
    });

    Cookies.set('experimentFlags', JSON.stringify(flagBag));
  };

  useEffect(() => {
    initPostHog(router);
    Sentry.setUser({ id: fetchUserIdentifier() });
    trackClickAnalytics();

    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        if (!process.env.NEXT_PUBLIC_FACEBOOK_PIXEL) {
          return;
        }

        ReactPixel.init(process.env.NEXT_PUBLIC_FACEBOOK_PIXEL);
        ReactPixel.pageView();

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <ThemeProvider theme={light}>
      <AlertServiceProvider>
        <FlaggingContext.Provider value={flagBag}>
          <FlaggingComponent flagBag={flagBag} />
          <SpamFlaggingProvider>
            <StoreContext>
              <ContainerWrapper>
                <Container>
                  <Header />
                  <ContentWrapper>
                    <Component {...pageProps} />
                    <Analytics />
                  </ContentWrapper>
                  <Footer />
                  <CookieBar />
                </Container>
              </ContainerWrapper>
            </StoreContext>
          </SpamFlaggingProvider>
        </FlaggingContext.Provider>
      </AlertServiceProvider>
    </ThemeProvider>
  );
};

export default WebsiteApp;
