import React, { useRef, useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { utils, Button } from '@makeship/core';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';

import posthog from 'posthog-js';
import Banner from '../Banner';
import Drawer from '../Drawer';
import { P1, P2, S2, P1Styles } from '../Typography';
import CartDropdown from '../CartDropdown';

import { useStore } from '../../store';
import { toggleCart } from '../../store/store.actions';
import { getNumItems } from '../../utils/cart';
import { useDebounce } from '../../utils/debounce';
import useWindowOffset from '../../utils/window';
import { SearchBar } from '../Input';
import { getProductsByQuery } from '../../api/product';
import { getProductIDFromShopifyGID, getShopifyQuery, getStage } from '../../utils/product';

import config from '../../../config.json';
import {
  getCollectionByHandle,
  getCreatorCollectionsByQuery,
  getSearchableCollectionsByQuery,
} from '../../api/collections';
import { CartEventLocations, ProductStage, PromotionContent } from '../../types/common';
import { getSortedSearchResults } from '../../utils/collections';
import { analyticsTrackViewCart, getCreator, isUserLoggedIn } from '../../utils/analytics';

const { collections, routes } = config;

const NavContainer = styled.div<{ offset: number }>`
  margin-bottom: ${({ offset, theme }) => offset > theme.bannerHeight && `${theme.headerHeight}px`};
`;

const HeaderWrapper = styled.div<{ offset: number }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${({ theme }) => theme.siteWidth};
  height: ${({ theme }) => `${theme.headerHeight}px`};
  background: linear-gradient(270deg, #1f0076 33.82%, #534aee 95.65%);
  position: ${({ offset, theme }) => (offset > theme.bannerHeight ? 'fixed' : 'relative')};
  top: 0;
  padding-right: 24px;
  z-index: 3;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding-right: 16px;
  }
`;

const HeaderSide = styled.div`
  flex: 1 1;
  display: flex;
  align-items: center;
`;

const LeftHeaderSide = styled(HeaderSide)`
  justify-content: flex-start;
`;

const CenterHeaderSide = styled(HeaderSide)`
  justify-content: center;
  flex: 1 0;
  & > a {
    white-space: nowrap;
  }
`;

const RightHeaderSide = styled(HeaderSide)`
  justify-content: flex-end;
`;

const HeaderNavItemStyles = css`
  border-bottom: 2px solid ${({ theme }) => theme.colors.transparent};
  text-decoration: none;
  transition: border-bottom 0.25s;

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }

  &:focus-visible {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
    outline: none;
  }

  &:active {
    color: ${({ theme }) => theme.colors.neutral1};
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.headerBreakpoint}px) {
    display: none;
  }
`;

const HeaderNavItem = styled.a`
  ${HeaderNavItemStyles}
  ${P1Styles}

  margin: 0 16px;
  color: ${({ theme }) => theme.colors.neutral1};
`;

const AuthNavItem = styled(HeaderNavItem)`
  white-space: nowrap;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.headerBreakpoint}px) {
    display: block;
  }
`;

const SearchBarWrapper = styled.form`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.headerBreakpoint}px) {
    display: none;
  }
`;

const SearchResultsCard = styled.div`
  position: absolute;
  width: 400px;
  margin-top: 8px;
  background: ${({ theme }) => theme.colors.neutral1};
  border-radius: 3px;
  box-shadow: 0px 2px 16px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
  display: flex;
  flex-direction: column;
`;

const SearchResultWrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral2};
  }
`;

const SearchForm = styled.form`
  width: 100%;
`;

const SearchResultImage = styled.div`
  width: 72px;
  min-width: 72px;
  height: 72px;
`;

const SearchResultInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
`;

const SearchResultsPriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SearchResultsNotLive = styled(S2)<{ soldOut?: boolean }>`
  color: ${({ theme, soldOut }) => soldOut && theme.colors.error};
  ${({ soldOut }) => soldOut && `text-transform: uppercase;`}
`;

const SearchBarDropDown = styled.div<{ offset: number; showMenu: boolean }>`
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.headerBreakpoint}px) {
    transform: ${({ showMenu }) => (showMenu ? 'translate(0)' : 'translate(100%)')};
    transition: transform 0.25s ease-in-out;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100vw - 16px);
    max-width: 450px;
    background-color: ${({ theme }) => theme.colors.neutral1};
    box-shadow: 0px 2px 16px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
    z-index: 11;
  }
`;

const SearchBarDropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
`;

const SearchBarDropDownStyles = css`
  background-color: ${({ theme }) => theme.colors.neutral2};
  height: 40px;
  width: 90%;
`;

const SearchBarDropDownResults = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

const SearchBarSuggested = styled(S2)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  padding: 16px 0 0 16px;
  margin-bottom: 12px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding-top: 0;
  }
`;

const SearchBarViewAll = styled(Button.PrimaryLink)`
  padding: 16px;
  align-self: flex-end;
`;

const CancelIconWrapper = styled.div`
  cursor: pointer;
  margin-top: 8px;
`;

const MenuWrapper = styled.div`
  display: none;
  cursor: pointer;
  min-width: 32px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.headerBreakpoint}px) {
    display: flex;
    justify-content: center;
  }
`;

const MagnifyingGlassWrapper = styled.div`
  display: none;
  margin-right: 16px;
  cursor: pointer;
  min-width: 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.headerBreakpoint}px) {
    display: flex;
  }
`;

const Logo = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 18px 8px 8px;
  min-width: 41px;
`;

const CartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  min-width: 32px;
`;

const CartIconWrapper = styled.div`
  min-width: 24px;
`;

const CartQuantity = styled(P1)`
  color: ${({ theme }) => theme.colors.neutral1};
  padding-left: 4px;
`;

const NewText = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

type NavItem = {
  title: string;
  href: string;
  id?: string;
  isNew?: boolean;
};

const navItems: NavItem[] = [
  {
    title: 'Explore Petitions',
    href: routes.shopPetitions,
    id: 'explore-petitions-cta',
    isNew: true,
  },
  {
    title: 'Explore Campaigns',
    href: routes.shopLive,
    id: 'explore-campaigns-cta',
    isNew: false,
  },
];

const promotionContentArray: PromotionContent[] = [];

const Header: React.FC = () => {
  const { state, dispatch } = useStore();
  const { pagination } = config;
  const [searchQuery, setSearchQuery] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [showSearchDropDown, setShowSearchDropDown] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchResults, setSearchResults] = useState<Shopify.ProductEdge[]>([]);
  const [suggestions, setSuggestions] = useState<Shopify.ProductEdge[]>([]);
  const [hasFetchedSuggestions, setHasFetchedSuggestions] = useState(false);
  const offset = useWindowOffset();
  const cartRef = useRef<HTMLDivElement>(null);
  const resultsRef = useRef<HTMLFormElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const childInputRef = useRef<HTMLInputElement>(null);
  const router = useRouter();
  const debouncedSearchQuery = useDebounce<string>(searchQuery.trim(), 400);

  const handleClick = useCallback((e: MouseEvent) => {
    // outside dropdown div
    if (
      !!searchRef.current &&
      !searchRef.current.contains(e.target as Node) &&
      !!resultsRef.current &&
      !resultsRef.current.contains(e.target as Node)
    ) {
      clear();
    }
  }, []);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  useEffect(() => {
    debouncedSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const handleFetchSuggestions = () => {
    if (!hasFetchedSuggestions) {
      getCollectionByHandle(collections.topCampaigns, undefined, 1, pagination.defaultSearchSuggestions).then(
        (data) => {
          setSuggestions(data?.products.edges || []);
          setHasFetchedSuggestions(true);
        },
      );
    }
  };
  const debouncedSearch = async (query: string) => {
    if (!query) {
      setSearchResults([]);
      return;
    }
    const [productsByTitle, productsByCreator, searchableProducts] = await Promise.all([
      getProductsByQuery(getShopifyQuery(query), 3, '', 'UPDATED_AT', true),
      getCreatorCollectionsByQuery(getShopifyQuery(query), 3, '', 'UPDATED_AT', true),
      getSearchableCollectionsByQuery(getShopifyQuery(query), 3, '', 'UPDATED_AT', true),
    ]);
    const uniqueSearchResults = getSortedSearchResults(productsByTitle, productsByCreator, searchableProducts);

    setSearchResults(uniqueSearchResults.slice(0, 3));
  };

  const clear = () => {
    setShowSearchDropDown(false);
    setShowSearchResults(false);
  };

  const handleSearchResultClick = (product: Shopify.Product, searchQuery: string) => {
    posthog.capture('search_result_click', {
      product_title: product.title,
      product_id: getProductIDFromShopifyGID(product.id),
      search_query: searchQuery.trim(),
      location: 'search_bar_suggestion',
      is_logged_in: isUserLoggedIn(state.user),
      product_stage: getStage(product.tags),
    });
    clear();
    setSearchQuery('');
  };

  const search = (e: React.FormEvent) => {
    e.preventDefault();
    posthog.capture('search_submit', {
      search_query: searchQuery.trim(),
      is_logged_in: isUserLoggedIn(state.user),
    });
    clear();

    router.push({
      pathname: '/search',
      query: {
        q: searchQuery.trim(),
      },
    });
  };

  const handleSearchIconClick = () => {
    handleFetchSuggestions();
    setShowSearchDropDown(true);
    posthog.capture('search_bar_click', {
      is_logged_in: isUserLoggedIn(state.user),
    });
    childInputRef.current?.focus();
  };

  const handleMenuClick = () => {
    setShowMenu(true);
    posthog.capture('header_menu_click', { is_logged_in: isUserLoggedIn(state.user) });
  };

  return (
    <NavContainer offset={offset}>
      <Banner hidden={false} promotionContentArray={promotionContentArray} />
      <HeaderWrapper offset={offset}>
        <LeftHeaderSide>
          <Link href={routes.home} passHref>
            <Logo
              onClick={() => posthog.capture('header_makeshipLogo_click', { is_logged_in: isUserLoggedIn(state.user) })}
            >
              <Image src="/assets/header-logo.svg" width={41} height={42} quality={90} priority />
            </Logo>
          </Link>
          <MenuWrapper data-testid="menu-wrapper" onClick={handleMenuClick}>
            <Image
              data-testid="menu-image"
              src="/assets/icons/hamburger-icon.png"
              width={32}
              height={18}
              quality={90}
              priority
            />
          </MenuWrapper>
          <SearchBarWrapper
            onSubmit={search}
            ref={resultsRef}
            onClick={() => posthog.capture('search_bar_click', { isUserLoggedIn: isUserLoggedIn(state.user) })}
          >
            <SearchBar
              placeholder="Search Here"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setShowSearchResults(true);
                setSearchQuery(e.target.value);
              }}
              onFocus={() => setShowSearchResults(true)}
              onClick={handleFetchSuggestions}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
            />
            {showSearchResults && (
              <SearchResultsCard>
                {searchQuery.length === 0 && <SearchBarSuggested>Suggested For You</SearchBarSuggested>}
                {(searchQuery.length > 0 ? searchResults : suggestions).map(({ node }) => {
                  const stage = getStage(node.tags);
                  const creator = getCreator(node);
                  return (
                    <Link key={node.id} href={`/products/${node.handle}`}>
                      <SearchResultWrapper onClick={() => handleSearchResultClick(node, searchQuery)}>
                        <SearchResultImage>
                          <Image
                            src={node.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
                            alt={node.images.edges[0]?.node.altText || 'product image'}
                            width={72}
                            height={72}
                            quality={90}
                            unoptimized
                          />
                        </SearchResultImage>
                        <SearchResultInfo>
                          <P2>{node.title}</P2>
                          {creator && <P2>By {creator}</P2>}
                          {stage !== ProductStage.Live && stage !== ProductStage.Petition && (
                            <SearchResultsPriceWrapper>
                              <SearchResultsNotLive soldOut={stage !== ProductStage.ComingSoon}>
                                {stage === ProductStage.ComingSoon ? 'Coming Soon' : 'Sold Out'}
                              </SearchResultsNotLive>
                            </SearchResultsPriceWrapper>
                          )}
                        </SearchResultInfo>
                      </SearchResultWrapper>
                    </Link>
                  );
                })}
                {searchQuery.length > 0 && searchResults.length > 0 && (
                  <SearchBarViewAll>View All Results</SearchBarViewAll>
                )}
              </SearchResultsCard>
            )}
          </SearchBarWrapper>
        </LeftHeaderSide>
        <CenterHeaderSide>
          {navItems.map((item) => (
            <Link key={item.title} href={item.href} passHref>
              <HeaderNavItem
                data-testid={item.id}
                onClick={() =>
                  posthog.capture(`header_navItem_click`, {
                    nav_item_title: item.title,
                    is_logged_in: isUserLoggedIn(state.user),
                  })
                }
              >
                <NewText>{item.isNew && 'New! '}</NewText>
                {item.title}
              </HeaderNavItem>
            </Link>
          ))}
        </CenterHeaderSide>
        <RightHeaderSide>
          <Link href={state.user?.accessToken ? routes.account : routes.login} passHref>
            <AuthNavItem
              onClick={() =>
                posthog.capture(`header_${state.user?.accessToken ? 'myAccount' : 'signUp'}_click`, {
                  is_logged_in: isUserLoggedIn(state.user),
                })
              }
            >
              {state.user?.accessToken ? 'My Account' : 'Sign In/Up'}
            </AuthNavItem>
          </Link>
          <MagnifyingGlassWrapper onClick={handleSearchIconClick}>
            <Image src="/assets/icons/magnifying-glass-icon.svg" width="23" height="24" quality={90} priority />
          </MagnifyingGlassWrapper>
          <CartWrapper
            onClick={() => {
              dispatch(toggleCart());
              analyticsTrackViewCart(
                state.cart.checkout?.lineItems.edges,
                state.cart.checkout?.totalPriceV2.amount,
                state.user,
                CartEventLocations.CartPopout,
              );
            }}
            ref={cartRef}
          >
            <CartIconWrapper>
              <Image src="/assets/icons/cart-icon.svg" width="24" height="24" quality={90} priority />
            </CartIconWrapper>
            <CartQuantity>{getNumItems(state.cart.checkout)}</CartQuantity>
          </CartWrapper>
        </RightHeaderSide>
      </HeaderWrapper>
      <CartDropdown cartRef={cartRef} offset={offset} />
      <Drawer showMenu={showMenu} setShowMenu={setShowMenu} />
      <SearchBarDropDown offset={offset} ref={searchRef} showMenu={showSearchDropDown}>
        <SearchForm onSubmit={search}>
          <SearchBarDropDownHeader>
            <SearchBar
              placeholder="Search Here"
              value={searchQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value);
              }}
              customStyles={SearchBarDropDownStyles}
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              inputRef={childInputRef}
            />
            <CancelIconWrapper onClick={() => setShowSearchDropDown(false)}>
              <Image src="/assets/icons/close-icon.svg" width="16" height="16" quality={90} />
            </CancelIconWrapper>
          </SearchBarDropDownHeader>
          <SearchBarDropDownResults>
            {(searchQuery.length === 0 || searchResults.length === 0) && (
              <SearchBarSuggested>Suggested For You</SearchBarSuggested>
            )}
            {(searchQuery.length > 0 && searchResults.length > 0 ? searchResults : suggestions).map(({ node }) => {
              const stage = getStage(node.tags);
              const creator = getCreator(node);
              return (
                <Link key={node.id} href={`/products/${node.handle}`}>
                  <SearchResultWrapper onClick={() => handleSearchResultClick(node, searchQuery)}>
                    <SearchResultImage>
                      <Image
                        src={node.images.edges[0]?.node.transformedSrc || '/assets/default_product_asset.png'}
                        alt={node.images.edges[0]?.node.altText || 'product image'}
                        width={72}
                        height={72}
                        quality={90}
                        unoptimized
                      />
                    </SearchResultImage>
                    <SearchResultInfo>
                      <P2>{node.title}</P2>
                      {creator && <P2>By {creator}</P2>}
                      {stage !== ProductStage.Live && stage !== ProductStage.Petition && (
                        <SearchResultsPriceWrapper>
                          <SearchResultsNotLive soldOut={stage !== ProductStage.ComingSoon}>
                            {stage === ProductStage.ComingSoon ? 'Coming Soon' : 'Sold Out'}
                          </SearchResultsNotLive>
                        </SearchResultsPriceWrapper>
                      )}
                    </SearchResultInfo>
                  </SearchResultWrapper>
                </Link>
              );
            })}
            {searchQuery.length > 0 && searchResults.length > 0 && (
              <SearchBarViewAll>View All Results</SearchBarViewAll>
            )}
          </SearchBarDropDownResults>
        </SearchForm>
      </SearchBarDropDown>
    </NavContainer>
  );
};

export default Header;
