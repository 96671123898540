import { GetServerSidePropsContext } from 'next';
import { v4 as uuidv4 } from 'uuid';
import { parse, serialize } from 'cookie';
import { anonIDKey } from './localstorage';

export const accessTokenKey = 'accessToken';
export const usernameKey = 'username';
export const checkoutIdKey = 'checkoutId';

export const handleAnonID = async (ctx: GetServerSidePropsContext) => {
  const cookies = parse(ctx.req.headers.cookie || '');
  let { anonID } = cookies;

  if (!anonID) {
    anonID = uuidv4() as string;
    const cookie = serialize(anonIDKey, anonID, {
      maxAge: 365 * 24 * 60 * 60,
      path: '/',
      sameSite: 'lax',
    });

    ctx.res.setHeader('Set-Cookie', cookie);
  }

  return anonID;
};
