import { utils } from '@makeship/core';
import { getCollectionByHandle } from '../api/collections';
import { EvergreenInventoryState, productMOQ, ProductStage, ProductTag } from '../types/common';
import { getStage, parseMetafields, productArrayContainsProductTag } from './product';
import config from '../../config.json';

const { collections } = config;

export const filterProducts = (
  products: Shopify.ProductEdge[],
  showEvergreen = true,
  showLivePetitions = true,
  showPastPetitions = true,
): Shopify.ProductEdge[] => {
  let productsToDisplay = products.filter((product) => {
    const { tags } = product.node;
    const isHidden = tags.includes(ProductTag.Hidden);
    const isDraft = tags.includes(ProductStage.Draft);

    return !isHidden && !isDraft && isCampaignLaunched(product);
  });

  if (!showEvergreen) {
    productsToDisplay = productsToDisplay.filter((product) => !product.node.tags.includes('evergreen'));
  }

  if (!showLivePetitions) {
    productsToDisplay = productsToDisplay.filter((product) => !product.node.tags.includes(ProductStage.Petition));
  }

  if (!showPastPetitions) {
    productsToDisplay = productsToDisplay.filter(
      (product) => !product.node.tags.includes(ProductStage.PetitionSuccess),
    );
  }

  return productsToDisplay;
};

const isCampaignLaunched = (product: Shopify.ProductEdge): boolean =>
  product.node.tags.includes(ProductStage.Live) ||
  product.node.tags.includes(ProductStage.ComingSoon) ||
  product.node.tags.includes(ProductStage.Past) ||
  product.node.tags.includes(ProductStage.Petition) ||
  product.node.tags.includes(ProductStage.PetitionSuccess);

export const getProductsFromCollection = async (handle: string): Promise<Shopify.ProductEdge[]> => {
  let products: Shopify.ProductEdge[] = [];
  let cursor: string | undefined;

  do {
    // eslint-disable-next-line no-await-in-loop
    const newProducts = await getNextPage(handle, cursor);
    cursor = newProducts?.[newProducts.length - 1]?.cursor;

    if (!newProducts || newProducts.length === 0) {
      break;
    }

    const productsToAdd = filterProducts(newProducts || [], isEvergreenCreator(handle));

    products = products.concat(productsToAdd);
  } while (cursor);

  return products;
};

export const getNextPage = async (
  handle: string,
  lastProductCursor: string | undefined,
): Promise<Shopify.ProductEdge[] | undefined> => {
  const newCollection = await getCollectionByHandle(handle, lastProductCursor);

  return newCollection?.products?.edges;
};

export const getCollectionProducts = (
  products: Shopify.ProductEdge[],
  endingSoon?: boolean,
  showEvergreen?: boolean,
  showLivePetitions?: boolean,
  showPastPetitions?: boolean,
): Shopify.ProductEdge[] =>
  getSortedProducts(filterProducts(products, showEvergreen, showLivePetitions, showPastPetitions), endingSoon);

export const getSortedProducts = (products: Shopify.ProductEdge[], endingSoon?: boolean): Shopify.ProductEdge[] =>
  products.sort(({ node: a }, { node: b }) => {
    const aStage = getStage(a.tags);
    const bStage = getStage(b.tags);

    const aMetafields = parseMetafields(a.metafields);
    const bMetafields = parseMetafields(b.metafields);

    const aMoq = Number(aMetafields.moq) || productMOQ[a.productType] || config.defaultMOQ;
    const bMoq = Number(bMetafields.moq) || productMOQ[b.productType] || config.defaultMOQ;

    const aIsLimited = a.tags.includes('limited');
    const bIsLimited = b.tags.includes('limited');

    // Sort ending soon collection by remaining time
    if (endingSoon) {
      if ((aMetafields?.enddate || '') < (bMetafields?.enddate || '')) return -1;
      if ((aMetafields?.enddate || '') > (bMetafields?.enddate || '')) return 1;
      return 0;
    }

    if (aStage === bStage) {
      if (aStage === ProductStage.ComingSoon) {
        // Ensure products without launch date is at back of sorted array
        if ((aMetafields?.launch || Number.MAX_VALUE) < (bMetafields?.launch || Number.MAX_VALUE)) return -1;
        if ((aMetafields?.launch || Number.MAX_VALUE) > (bMetafields?.launch || Number.MAX_VALUE)) return 1;
        return 0;
      }
      if (aStage === ProductStage.Past) {
        if (
          Number(aMetafields?.totalsold || Math.abs(Number(a.totalInventory || 0))) / aMoq <
          Number(bMetafields?.totalsold || Math.abs(Number(b.totalInventory || 0))) / bMoq
        )
          return 1;
        if (
          Number(aMetafields?.totalsold || Math.abs(Number(a.totalInventory || 0))) / aMoq >
          Number(bMetafields?.totalsold || Math.abs(Number(b.totalInventory || 0))) / bMoq
        )
          return -1;
        return 0;
      }
    } else if (aStage === ProductStage.Live || bStage === ProductStage.Live) {
      // Show live products first
      return aStage === ProductStage.Live ? -1 : 1;
    } else if (aStage === ProductStage.ComingSoon || bStage === ProductStage.ComingSoon) {
      // Show Coming Soon products next
      return aStage === ProductStage.ComingSoon ? -1 : 1;
    } else if (aStage === ProductStage.Failed || bStage === ProductStage.Failed) {
      // Show failed and past campaigns last
      return aStage === ProductStage.Failed ? 1 : -1;
    } else if (aStage === ProductStage.Past || bStage === ProductStage.Past) {
      return aStage === ProductStage.Past ? 1 : -1;
    }

    const aInventory = Math.abs(a.totalInventory || 0);
    const bInventory = Math.abs(b.totalInventory || 0);

    if (aIsLimited || bIsLimited) {
      if (aIsLimited && bIsLimited) {
        return Number(aMoq - aInventory) < Number(bMoq - bInventory) ? 1 : -1;
      }
      if (aIsLimited) {
        return Number(aMoq - aInventory) < Number(bInventory) ? 1 : -1;
      }
      if (bIsLimited) {
        return Number(aInventory) < Number(bMoq - bInventory) ? 1 : -1;
      }
    }

    return Number(aInventory) / aMoq < Number(bInventory) / bMoq ? 1 : -1;
  });

export const getEndingSoonProducts = async (handle: string): Promise<Shopify.ProductEdge[]> => {
  const liveCampaigns = await getProductsFromCollection(handle);

  const endingSoon = filterProducts(liveCampaigns).filter(({ node }) => {
    const metafields = parseMetafields(node.metafields);
    if (metafields.enddate) {
      const diff = utils.parseDate(metafields.enddate).diff(utils.dateNow(), 'day');
      return diff >= 0 && diff < 7;
    }
    return false;
  });

  return endingSoon;
};

export const getNewArrivalProducts = async (handle: string): Promise<Shopify.ProductEdge[]> => {
  const liveCampaigns = await getProductsFromCollection(handle);

  const newArrivals = filterProducts(liveCampaigns).filter(({ node }) => {
    const metafields = parseMetafields(node.metafields);
    if (metafields.launch) {
      const diff = utils.dateNow().diff(utils.parseDate(metafields.launch), 'day');
      return diff >= 0 && diff < 7;
    }
    return false;
  });

  return newArrivals;
};

export const getSortedSearchResults = (
  productsByTitle: Shopify.ProductEdge[],
  productsByCreator: Shopify.ProductEdge[],
  searchableProducts: Shopify.ProductEdge[],
): Shopify.ProductEdge[] => {
  const isEndingSoonCarousel = false;
  const searchResults = productsByCreator.concat(productsByTitle, searchableProducts);
  const sortedResults = getSortedProducts(filterProducts(searchResults), isEndingSoonCarousel);

  const seen = new Set();

  const uniqueSearchResults = sortedResults.filter((result) => {
    const duplicate = seen.has(result.node.id);
    if (!duplicate) {
      seen.add(result.node.id);
    }
    return !duplicate;
  });

  return uniqueSearchResults;
};

export const isSpecialCollection = (handle: string | undefined): boolean =>
  handle === collections.webtoon ||
  handle === collections.runescape ||
  handle === collections.ninjakiwi ||
  handle === collections.kingsisleentertainment ||
  handle === collections.vinyls ||
  handle === collections.petitions ||
  handle === collections.guiltygearstrive ||
  handle === collections.hololive;

export const filterSuccessfulProducts = (productEdges: Shopify.ProductEdge[]): Shopify.ProductEdge[] =>
  [...productEdges].filter((productEdge) => getStage(productEdge.node.tags) !== ProductStage.Failed);

export const filterLiveFromCollection = (products: Shopify.ProductEdge[]): Shopify.ProductEdge[] =>
  [...products].filter((product) => getStage(product.node.tags) === ProductStage.Live);

export const filterLiveAndComingSoonFromCollection = (products: Shopify.ProductEdge[]): Shopify.ProductEdge[] =>
  [...products].filter(
    (product) =>
      getStage(product.node.tags) === ProductStage.Live || getStage(product.node.tags) === ProductStage.ComingSoon,
  );

export const getIsCompleteTheCollectionEligible = (
  sameCreatorProducts: Shopify.ProductEdge[],
  makeshiftCollectionProducts: Shopify.ProductEdge[],
  hasProductionDesign: boolean,
  stage: ProductStage,
  isSoldOut: boolean,
  product: Shopify.Product,
  pinAddOnProduct?: Shopify.Product | undefined | null,
): boolean => {
  const hasEligibleSameCreatorProducts =
    sameCreatorProducts.length > 0 && !productArrayContainsProductTag(sameCreatorProducts, ProductStage.Petition);

  const hasEligibleMakeshiftCollectionProducts =
    makeshiftCollectionProducts.length > 0 &&
    !productArrayContainsProductTag(makeshiftCollectionProducts, ProductStage.Petition);

  const hasEligibleProducts =
    pinAddOnProduct || hasEligibleSameCreatorProducts || hasEligibleMakeshiftCollectionProducts;

  const isProductAvailable = (stage === ProductStage.Live && product.availableForSale) || stage === ProductStage.Draft;

  const isEligible = hasEligibleProducts && !hasProductionDesign && isProductAvailable && !isSoldOut;

  return isEligible;
};

export const isEvergreenCreator = (handle: string): boolean => handle === 'the-click';

export const shouldDisplayBadge = (
  stage: ProductStage,
  sale: boolean,
  inventoryState: EvergreenInventoryState | undefined,
) => {
  const stagesWithBadge = [ProductStage.ComingSoon, ProductStage.Past, ProductStage.PetitionSuccess];

  return (
    stagesWithBadge.includes(stage) ||
    sale ||
    inventoryState === EvergreenInventoryState.LowStock ||
    inventoryState === EvergreenInventoryState.SoldOut
  );
};

export const filterCarouselProducts = (products: Shopify.ProductEdge[], productID: string): Shopify.ProductEdge[] =>
  products
    .filter((product) => {
      const isHidden = product.node.tags.includes(ProductTag.Hidden);
      const isDraft = product.node.tags.includes(ProductStage.Draft);

      return product.node.tags.includes(ProductStage.PetitionSuccess)
        ? isCampaignLaunched(product) && !isHidden && !isDraft
        : !isHidden &&
            !isDraft &&
            !product.node.tags.includes(ProductStage.Petition) &&
            !product.node.tags.includes(ProductStage.PetitionFailed);
    })
    .filter((product) => product.node.id !== productID);

export const insertHololiveToSidebar = (collections: SidebarItemsType[]) => {
  const hololive = {
    title: 'hololive production',
    handle: 'hololive',
    isRemovableSidebarItem: true,
    isNew: false,
  };

  const collection = collections.find((collection) => collection.title === 'Shop Collections');
  if (collection) {
    const { links } = collection;
    const itemExists = links.some((link) => link.title === hololive.title || link.handle === hololive.handle);

    if (!itemExists) {
      const index = links.findIndex((link) => link.handle === 'guilty-gear-strive');
      if (index !== -1) {
        links.splice(index + 1, 0, hololive);
      }
    }
  }
};
