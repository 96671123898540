import React, { useContext, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import styled, { ThemeContext } from 'styled-components';
import { utils, Button } from '@makeship/core';
import { Size } from '@makeship/core/src/components/Button/button';
import { getCookieConsentValue } from 'react-cookie-consent';
import posthog from 'posthog-js';
import CartProduct from './product';
import { H5, S2, P2 } from '../Typography';
import { useStore } from '../../store';
import { hideCart } from '../../store/store.actions';
import config from '../../../config.json';
import { getFormattedAmount } from '../../utils/accounting';
import { analyticsTrackStartCheckout, getGALinkerParam, sendGTMEvent } from '../../utils/analytics';
import { getCheckoutUrl } from '../../utils/url';
import { CartEventLocations, ProductStage, ProductTag, ProductType } from '../../types/common';
import { getProductIDFromShopifyGID, getProductType, getStage, parseMetafields } from '../../utils/product';
import AddOns from './addOns';
import { useWindowSize } from '../../hooks/useWindowSize';
import { getCheckoutCollectionsByHandles } from '../../api/collections';
import { getProductByID } from '../../api/product';

const { elements } = config;

const CartDropdownWrapper = styled.div<{ showCart: boolean; offset: number }>`
  width: 424px;
  background-color: ${({ theme }) => theme.colors.neutral1};
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  width: 473px;
  height: 100%;
  max-height: 100%;
  padding: 16px 16px 24px 16px;
  box-shadow: 0px 2px 16px rgba(20, 20, 20, 0.35);
  z-index: 11;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    top: ${({ offset, theme }) =>
      offset > theme.bannerHeight ? `${theme.headerHeight}px` : `${theme.headerHeight - offset}px`};
    width: calc(100% - 4px);
    height: calc(
      100vh -
        ${({ offset, theme }) =>
          offset > theme.bannerHeight
            ? `${theme.headerHeight}px` // When scrolled down and not at top
            : `${theme.bannerHeight + theme.headerHeight}px`}
    );
  }
`;

const CartDropdownHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
`;

const CloseButton = styled(Image)`
  cursor: pointer;
`;

const CartSection = styled.div`
  width: 100%;
  padding-right: 8px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
    padding-right: 0;
  }
`;

const EmptyCartWrapper = styled(CartSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const CartProductList = styled(CartSection)`
  overflow-y: scroll;
`;

const EmptyCartText = styled(H5)`
  margin-top: 16px;
`;

const ShopPageWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  display: flex;
  justify-content: flex-end;
  padding-top: 0;
  margin-top: 24px;
`;

const CartDropdownFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 16px;
`;

const CartButtonRow = styled.div<{ hasCookies: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    ${({ hasCookies }) =>
      hasCookies &&
      `
      margin-bottom: 130px;
    `}
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    ${({ hasCookies }) =>
      hasCookies &&
      `
      margin-bottom: 160px;
    `}
  }
`;

const CheckoutButtonPrimary = styled(Button.Primary)<{ disabled: boolean }>`
  display: block;
  width: 100%;
  margin-left: 8px;
  ${({ disabled }) => disabled && 'cursor: default;'};
  &:active {
    ${({ disabled }) => disabled && 'box-shadow: none;'};
  }
`;

const CartDropDownHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CartPriceDiscountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DiscountAmount = styled(P2)`
  color: ${({ theme }) => theme.colors.error};
  margin: 0 12px;
  font-weight: 600;
`;

const OldPrice = styled(P2)`
  text-decoration: line-through;
  color: ${({ theme }) => theme.colors.neutral5};
`;

const AddOnsContainer = styled.div`
  margin-top: auto;
`;

const CartTitle = styled(S2)``;

const CartText = styled(P2)``;

const CartBackground = styled.div<{ showCart: boolean }>`
  transform: ${({ showCart }) => (showCart ? 'translate(0)' : 'translate(105%)')};
  transition: transform 0.25s ease-in-out;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    transition: transform 0.3s ease-in-out;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    transition: transform 0.4s ease-in-out;
  }
`;

const StickyCartFooter = styled.div`
  border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  position: sticky;
  bottom: 0;
`;

type CartDropdownProps = {
  cartRef: React.RefObject<HTMLDivElement>;
  offset: number;
};

const CartDropdown: React.FC<CartDropdownProps> = ({ cartRef, offset }: CartDropdownProps) => {
  const { state, dispatch } = useStore();
  const [totalBeforeShopifyDiscounts, setTotalBeforeShopifyDiscounts] = useState(0.0);
  const [totalAfterShopifyDiscounts, setTotalAfterShopifyDiscounts] = useState(0.0);
  const [isLoadingDiscounts, setIsLoadingDiscounts] = useState(false);
  const [cookiesDisplayed, setCookiesDisplayed] = useState(false);
  const [isMobileViewport, setIsMobileViewport] = useState<boolean>(false);
  const [addOnSuggestion, setAddOnSuggestion] = useState<Shopify.Product | undefined>(undefined);
  const [isAddOnAccepted, setIsAddOnAccepted] = useState<boolean>(false);
  const [pinAddOnProduct, setPinAddOnProduct] = useState<Shopify.Product | null>(null);
  const [isPlushPinBundle, setIsPlushPinBundle] = useState<boolean>(false);

  const empty = !state.cart.checkout || state.cart.checkout?.lineItems.edges.length === 0;
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();

  const size = useWindowSize();
  const theme = useContext(ThemeContext);

  const handleClick = (e: MouseEvent) => {
    if (
      !!ref.current &&
      !ref.current.contains(e.target as Node) &&
      !cartRef.current?.contains(e.target as Node) &&
      (e.target as HTMLElement).id !== elements.addToCartButton
    ) {
      dispatch(hideCart());
    }
  };

  const handleClickCheckout = async () => {
    const linkerParam = getGALinkerParam();
    const url = await getCheckoutUrl(state.cart.checkout?.webUrl, state.user?.accessToken, linkerParam);
    return router.push(url);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  useEffect(() => {
    setIsLoadingDiscounts(true);
    let temp = 0.0;

    state.cart.checkout?.lineItems.edges.forEach((item) => {
      temp += item.node.variant?.compareAtPriceV2
        ? item.node.variant?.compareAtPriceV2.amount * item.node.quantity
        : item.node.variant?.priceV2.amount * item.node.quantity;
    });

    setTotalBeforeShopifyDiscounts(temp);
    setTotalAfterShopifyDiscounts(state.cart.checkout?.totalPriceV2.amount || 0.0);
  }, [state.cart.checkout?.lineItems.edges]);

  useEffect(() => {
    setIsLoadingDiscounts(false);
  }, [totalAfterShopifyDiscounts]);

  useEffect(() => {
    setCookiesDisplayed(getCookieConsentValue() !== 'true');
  }, [getCookieConsentValue()]);

  useEffect(() => {
    const getAddOns = async () => {
      setIsPlushPinBundle(false);
      const lineItems = state.cart.checkout?.lineItems.edges;
      if (!lineItems || lineItems.length >= 3 || lineItems.length === 0) {
        setAddOnSuggestion(undefined);
        return;
      }

      if (addOnSuggestion && !cartContainsProduct(addOnSuggestion)) {
        return;
      }

      let rawSuggestion: Shopify.Product | undefined;

      const creatorHandles = state.cart.checkout?.lineItems.edges
        .map(({ node }) => {
          const metafields = parseMetafields(node.variant?.product.metafields);
          return metafields.creator?.toLowerCase().replaceAll(' ', '-');
        })
        .filter((metafield) => metafield !== undefined) as string[];

      const collections = await getCheckoutCollectionsByHandles(creatorHandles, undefined, 1, 15);

      for (const collection of collections) {
        const product = collection.products.edges.find((edge) => {
          const product = edge.node;
          return (
            getStage(product.tags) === ProductStage.Live &&
            !cartContainsProduct(product) &&
            !product.tags.includes(ProductTag.Hidden) &&
            !product.tags.includes(ProductTag.Draft) &&
            getProductType(product.productType) !== ProductType.Hoodie
          );
        });

        if (product) {
          rawSuggestion = product.node;
        }
      }

      // if a product in the cart has a pin add-on, suggest a pin add-on
      const promises = lineItems.map(async (item) => {
        const metafields = parseMetafields(item.node.variant?.product.metafields);
        if (item.node.variant?.product.productType === ProductType.EnamelPin1Pack || metafields.pinAddOn) {
          setIsPlushPinBundle(true);
        }

        if (metafields.pinAddOn) {
          const addOnProduct = await getProductByID(metafields.pinAddOn);
          if (addOnProduct && !cartContainsProduct(addOnProduct)) {
            return addOnProduct;
          }
        }
        return null;
      });

      const addOnSuggestions = await Promise.all(promises);

      const pinSuggestion = addOnSuggestions.find((suggestion) => suggestion !== null);

      if (pinSuggestion) {
        rawSuggestion = pinSuggestion;
        setPinAddOnProduct(pinSuggestion);
      }

      setAddOnSuggestion(rawSuggestion);
    };
    getAddOns();
  }, [state.cart.checkout?.lineItems.edges, isMobileViewport]);

  useEffect(() => {
    if (!addOnSuggestion || isAddOnAccepted || !state.cart.open) {
      return;
    }
    posthog.capture('add-ons-suggestion', {
      addOnID: getProductIDFromShopifyGID(addOnSuggestion.id),
    });
  }, [addOnSuggestion]);

  useEffect(() => {
    if (!size.width) {
      return;
    }
    // eslint-disable-next-line react/destructuring-assignment
    const isMobileViewport = size.width <= theme.breakpoints.mobile;
    setIsMobileViewport(isMobileViewport);
  }, [size]);

  const cartContainsProduct = (product: Shopify.Product): boolean => {
    const cartItems = state.cart.checkout?.lineItems.edges || [];

    return cartItems.some((item) => item.node.variant?.product.id === product.id);
  };

  useEffect(() => {
    if (cookiesDisplayed) {
      posthog.capture('cookie_consent_displayed');
    }
  }, [cookiesDisplayed]);

  return (
    <CartBackground showCart={state.cart.open}>
      <CartDropdownWrapper ref={ref} showCart={state.cart.open} offset={offset}>
        <>
          <CartDropDownHeaderWrapper>
            <CartDropdownHeader>
              <CartTitle>Your Cart</CartTitle>
              <CloseButton
                src="/assets/icons/close-icon.svg"
                width="16"
                height="16"
                onClick={() => {
                  dispatch(hideCart());
                }}
              />
            </CartDropdownHeader>
          </CartDropDownHeaderWrapper>
          {!empty ? (
            <>
              <CartProductList data-testid="cart-product-list">
                {state.cart.checkout?.lineItems.edges.map((item, index) => (
                  <CartProduct key={item.node.id} lineItem={item.node} index={index} />
                ))}
              </CartProductList>
            </>
          ) : (
            <EmptyCartWrapper>
              <Image src="/assets/icons/empty-cart.png" width={100} height={100} quality={90} />
              <EmptyCartText>Your cart is empty</EmptyCartText>
            </EmptyCartWrapper>
          )}
          <AddOnsContainer>
            {!empty &&
              state.cart.checkout?.lineItems.edges.length &&
              state.cart.checkout?.lineItems.edges.length < 3 &&
              !isAddOnAccepted && (
                <AddOns
                  pinAddOnProduct={pinAddOnProduct}
                  addOnSuggestion={addOnSuggestion}
                  setAddOnSuggestion={setAddOnSuggestion}
                  setIsAddOnAccepted={setIsAddOnAccepted}
                  isPlushPinBundle={isPlushPinBundle}
                />
              )}
          </AddOnsContainer>
          <ShopPageWrapper />
          <StickyCartFooter>
            <CartDropdownFooter>
              <CartText>Subtotal</CartText>
              <CartPriceDiscountWrapper>
                {!isLoadingDiscounts && totalAfterShopifyDiscounts < totalBeforeShopifyDiscounts ? (
                  <>
                    <DiscountAmount>
                      ${getFormattedAmount(totalAfterShopifyDiscounts.toString()) || '0.00'}
                    </DiscountAmount>
                    <OldPrice>${getFormattedAmount(totalBeforeShopifyDiscounts.toString()) || '0.00'}</OldPrice>
                  </>
                ) : (
                  <CartText>${getFormattedAmount(totalBeforeShopifyDiscounts.toString()) || '0.00'}</CartText>
                )}
              </CartPriceDiscountWrapper>
            </CartDropdownFooter>
            <CartButtonRow hasCookies={cookiesDisplayed}>
              <CheckoutButtonPrimary
                data-testid="checkout-button"
                disabled={empty}
                size={Size.large}
                onClick={async () => {
                  analyticsTrackStartCheckout(
                    state.cart.checkout?.lineItems.edges,
                    state.user,
                    CartEventLocations.CartPopout,
                    state.cart.checkout?.id,
                  );
                  sendGTMEvent('Cart Drawer', 'Request', 'Checkout 1');
                  handleClickCheckout();
                }}
              >
                Checkout
              </CheckoutButtonPrimary>
            </CartButtonRow>
          </StickyCartFooter>
        </>
      </CartDropdownWrapper>
    </CartBackground>
  );
};

export default CartDropdown;
