import React from 'react';
import styled from 'styled-components';

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span<{ checked: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme, checked }) => (checked ? theme.colors.primary : theme.colors.neutral3)};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: ${({ theme }) => theme.colors.neutral1};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    ${({ checked }) =>
      checked &&
      ` -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  `}
  }
`;

type ToggleSwitchProps = {
  checked: boolean;
  onToggle: () => void;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ checked, onToggle }: ToggleSwitchProps) => (
  <SwitchContainer>
    <SwitchInput type="checkbox" onChange={() => onToggle()} />
    <Slider checked={checked} />
  </SwitchContainer>
);

export default ToggleSwitch;
